<script>
import { VueTyper } from "vue-typer";
import {
  ArrowRightCircleIcon,
  ArrowUpIcon,
  EyeIcon,
  ArrowRightIcon,
  HeartIcon,
  BoldIcon,
  FeatherIcon,
  CodeIcon,
  MonitorIcon,
  UserCheckIcon,
  GitMergeIcon,
  SettingsIcon,
  VideoIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";



/**
 * Index-landing-one component
 */
export default {
  data() {
    return {     featuresData: [
        {
          icon: "uil uil-file-search-alt",
          title: "Research & Stratagy",
          description:
            " To ensure our digital marketing agency efforts are efficient and effective, and in-line with your long-term goals.",
        },
        {
          icon: "uil uil-airplay",
          title: "Increase Visibility",
          description:
            "Analyze your business to design and develop any type of stunning and creative asstes, that drive results for your business.",
        },
                {
          icon: "uil uil-process",
          title: "Grow Your Customer Base",
          description:
            "High search results for relevant keywords is one key way to ensure that your website remains top-of-mind for potential customers. ",
        },
        {
          icon: "uil uil-thumbs-up",
          title: "Maintain Online Presence",
          description:
            "Grow and engage your audience with effective SEO, social media strategy, build back-link to establish and maintain online presence. ",
        },
        {
          icon: "uil uil-rocket",
          title: "Boost Performance Media",
          description:
            "Stand out from the crowd with a targeted social media advertising, PPC Ads campaign that propels your message through the online noise.",
        },
        {
          icon: "uil uil-shield-check",
          title: "Proprietary Processes",
          description:
            "we surround ourselves with other leaders, and our education never stops. Our Proprietary Processes are time-tested and field proven.",
        },
      ],
      
      };
  },
  components: {
    Navbar,
    "vue-typer": VueTyper,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    EyeIcon,
    VideoIcon,
    ArrowRightIcon,
    ArrowRightCircleIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    MonitorIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon,
    HeartIcon,
    countTo,
  },
};



</script>




<template>
  <div>
    <Navbar :nav-light="true" />

    





    <!-- Hero Start -->
    <section class="bg-half-260 pb-lg-0 pb-md-4 bg-primary d-table w-100">
      <div class="bg-overlay" style="opacity: 0.9; margin-bottom:40px; background: url('images/background/desktop1w2.jpg') center bottom;"></div>
        
    

   



      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
      

            <div class="card login_page shadow rounded border-0 px-2">
              <div class="card-body">

                <form class="login-form justify-content-center">
                  <div class="row">
                    <div class="col-md-12">

                      <h4 class="text-center font-weight-bold">Get a free Price Estimate</h4>
                      <p class=" text-left-padding-30 text-md-left">
                 
                 <span class="element kd-our-service">
                   <span v-b-tooltip.hover title="Karukaj is an ancient best digital marketing agency or web development company in Bangladesh near Dhaka.  
                   Also known as low-priced best eCommerce website development company in Bangladesh. Be around us!"></span><strong>Our Service:</strong> <vue-typer
                     :text="[
                       'eCommerce Web Development',
                       'Web Design and Development',
                       'WordPress Website Design',
                        'Branding/Positioning',
                        'Social Media Graphics Kit',
                        'Video Content Creation',
                     ]"
                   ></vue-typer>
                 </span>          
                </p>
                      


                      <iframe height="400" width="100%" frameborder="0" src="https://members.karukaj.net/forms/quote/94a7dccf3fa8229fc290464e855691c3"  style="border:0"></iframe>

                    </div>
      

                  </div>
                </form>
              </div>
            </div>









          </div>
          <!--end col-->

 

        
              <div class="col-md-5 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0 text-md-right">



                
              


          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->











    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->






   <!-- Feature Start -->
    <section class="section">


      <div class="container mt-lg-0">
            <div class="row">
          
                <div class="col-md-4">
                </div>
                <!--end col-->
                <div class="col-md-8">
                    <div class="section-title text-md-right">

                      <h4 class="title mb-4"><span v-b-tooltip.hover title="Karukaj is one of the best digital marketing 
agency in Bangladesh or the best web development company in Bangladesh near Dhaka. On the other hand our website design price in Bangladesh
 is very affordable and hence known as best eCommerce website development company in Bangladesh." class="my-color-1 font-weight-bold"> Karukaj DIGITAL</span> | Built for Business</h4>

                        <p class="text-muted text-md-right">We express your praise digitally by empowering digital commerce and creating experiences that are initiative, engaging, and highly rewarding for ...</p>
                    </div>
                </div>
                <!--end col-->

            </div>
            <!--end row-->

        </div>
        <!--end container-->











      
      <div class="responsive-center container mt-4">

        <div class="row">
          <div
            class="col-md-4 col-12"
            v-for="(item, index) of featuresData"
            :key="index"
          >
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <i :class="`${item.icon} h1 my-color-1`"></i>
              </div>

              <div class="responsive-center content mt-4">
                <h5>{{ item.title }}</h5>
                <p class="text-muted mb-0">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->








      <div class="container mt-100 mt-60">




        <div class="row">
          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">


              <h4 class="heading text-dark mb-4">Our Creativity Is Your Success</h4>

   
              <p class="responsive-justify text-muted mb-0 mx-auto para-desc"> 
                <span v-b-tooltip.hover title="Even-though Karukaj does outsourcing in Bangladesh and 
                the most popular as the best eCommerce website development company in Bangladesh, who have any kind of custom expertise as a bset web development company in Bangladesh." class="text-dark">Karukaj DIGITAL</span>
                <span v-b-tooltip.hover title="best digital marketing agency"></span> 
                is now a fastest growing social media advertising, seo marketing service, and <a class="text-muted" target="_self" title="Karukaj 
                specially trained portfolio web developers are able to create any type of portfolio website design, 
                catalog website design, ecommerce website design or personal website design." href="/explore">best web design and web development company in bangladesh</a>. 
                Apart from service of digital marketing agency in Bnagladesh, we also manage some outsourcing business. Therefore, we believe;  
               the power of the internet is now at your fingertips, and with us, you’ll be doing great things in a few days.
              </p>


<br/>

              <div class="col-12 text-md-right">


<a href="javascript:void(0)" class="my-color-1 h6"><span v-b-tooltip.hover title="Our creative interactive after effect promo animation, or Ads, 
photography, 
video editing, and video post production service will charge your business marketing several times more forever." 
>So, let’s get started with updated technology.</span> <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
</div>



            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row" id="counter">
              <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="content text-center">
                  <h1 class="mb-0">
                    <countTo
                      :startVal="1"
                      :duration="6000"
                      :endVal="300"
                    ></countTo
                    >+
                  </h1>
                  <ul class="list-unstyled mb-0 h5">
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                  </ul>
                  <h6>Store Developed</h6>
                </div>
              </div>

              <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="content text-center">
                  <h1 class="mb-0">
                    <countTo
                      :startVal="1"
                      :duration="4000"
                      :endVal="1000"
                    ></countTo
                    >+
                  </h1>
                  <ul class="list-unstyled mb-0 h5">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6>Task Completed</h6>
                </div>
              </div>
            </div>



            <div class="row justify-ontent-right mt-4">
           
           <div class="col-12 text-center">
                               <h4>Received Accreditations</h4>
            </div>      
    

    
              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/home/lynda2.png"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/home/coursera.png"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                   src="images/home/udemy.png"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/home/freelancer.png"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->


            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
                    <div class="kd-custom-text-right">
              Trusted by over 100+ of the world’s leading outsourcing
              companies
            </div>
      </div>
      <!--end container-->











          <div class="col-12 mt-4 pt-2 text-center">

<br><br>





          </div>
          <!--end col-->



 <!--strat section-->
 <section>
            
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">technologies we're familiar with</h4>


                  <div class="alert alert-light alert-pills" role="alert">
                  
                  <span class="alert-content"> We are familiar with digital development-related </span>
                  <span class="badge badge-pill badge-outline-secondary mr-1"> Updated Technology </span>
                  </div>

                        
<br><br>



                </div>
                </div>
                <!--end col-->
 


    <div class="border-bottom"> </div>

 

          <carousel
              id="customer-testi"
              ref="carousel"
              dir="ltr"
              :per-page="1"
              class="owl-carousel owl-theme"
              :autoplay="true"
              :loop="true"
            >
              <slide>
             
              <div class="attach-icons-hero text-center py-5 py-sm-0">
                <a href="javascript:void(0)"><img src="/images/technology-tools/Python-learners-in-Bangladesh.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Vuejs"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/angular-website-design.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="CSS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/vuejs-website-design.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="SASS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/Laravel-Website-design-in-Bangladesh.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Bootstrap"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/wordpress-website-design.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="CSS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/nuxtjs-website-design.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="W3c valid"></a>
                 <a href="javascript:void(0)"><img src="/images/technology-tools/React.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="HTML"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/android-studio.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Ui Kit"></a>

            </div>
              </slide>
                            <slide>

                             
              <div class="attach-icons-hero text-center py-5 py-sm-0">
                <a href="javascript:void(0)"><img src="/images/technology-tools/php-and-mysql.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Vuejs"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/javascript.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="W3c valid"></a>

                <a href="javascript:void(0)"><img src="/images/technology-tools/jquery.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="SASS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/bootstrap.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Bootstrap"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/sass.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="HTML"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/css33.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="CSS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/html5.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="CSS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/bloggers.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Vuejs"></a>
            </div>
            
              </slide>
              <slide>

                             
              <div class="attach-icons-hero text-center py-5 py-sm-0">
                <a href="javascript:void(0)"><img src="/images/technology-tools/figma.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="SASS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/gw.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Ui Kit"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/AI.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="CSS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/AE.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="CSS"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/pr.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="W3c valid"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/audition.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Ui Kit"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/photoshop.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="HTML"></a>
                <a href="javascript:void(0)"><img src="/images/technology-tools/adobe-lightroom.png" class="img-fluid avatar avatar-md my-2 my-sm-0 mx-2 p-2 bg-white rounded-circle shadow" data-toggle="tooltip" data-placement="top" title="" alt="" data-original-title="Bootstrap"></a>
        
            </div>
            
              </slide>
          

          


            </carousel>






</section>
    <!--end section-->
    <!-- End -->














<br>
<br>





      <div class="container">
 



        <div class="row align-items-center">


          <div class="col-12 text-center">
            <div class="section-title mb-4">
              <h4 class="title mb-4">core solutions made for you</h4>


              <p class="text-muted">
                Explore the best ready to launch <span v-b-tooltip.hover title="Karukaj is well-regarded as the best digital marketing agency in Bangladesh, 
                as well as the best website design company in Bangladesh" class="text-muted">Karukaj DIGITAL</span> 
              solutions that are highly scalable at affordable prices.


              </p>



            </div>
          </div>
          <!--end col-->







              <div class="col-lg-12">
                  <carousel
                id="owl-fade"
                class="owl-carousel owl-theme"
                dir="ltr"
                :per-page="1"
                :autoplay="true"
                :loop="true"
                  >
                    <Slide>
                     <div class="row" style="margin-right: 0px; margin-left: 0px;">
   
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-01>
                        <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/landing-page-online-marketing-strategy.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">eCommerce Web Development</h4>
                                <p class="text-muted mb-0">Startup Expert eCommerce Solution</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-01" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/03.eCommerce-website-development-in-Bangladesh..jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  


        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-02>
          
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/icons8-web-design-64.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Web Design and Development</h4>
                                <p class="text-muted mb-0">UI/UX Designer to Senior Dev Team </p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-02" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/04.Custom-Web-Design-and-Development.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  



        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-03>
          
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/design-online.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">WordPress Website Design</h4>
                                <p class="text-muted mb-0">Self-manageable Featured Platform</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-03" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/05.WordPress-Website-Design-and-Redesign.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  





                <!--Start Service Area-->                  
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-04>
          
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/creative-process-online-marketing-strategy.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Branding/Positioning</h4>
                                <p class="text-muted mb-0">Premium Logo Designer Support</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-04" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/01.UI-UX-and-Logo-Design-Services-in-Bangladesh.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  







        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-05>
          
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/web-development-company.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Social Media Graphics Kit</h4>
                                <p class="text-muted mb-0">Amazing Graphics set for Social</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-05" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/08.Social-Media-Graphics-Kit.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  



        

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-06>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/video-online-marketing-strategy.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Video Content Creation</h4>
                                <p class="text-muted mb-0">AE/PR Show-stopping Creation</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-06" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/09.Animation-Video-Content-Creation-Services-in-Bangladesh.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  










        

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-07>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/adaptive-web-development-company.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Adnoird App & IOS APP</h4>
                                <p class="text-muted mb-0">Add Additional Store via App</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-07" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/12.Android-App-and-IOS-APP.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  






        


        

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-08>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/customer-online-marketing-strategy.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Management Software</h4>
                                <p class="text-muted mb-0">Manage Day-to-Day Activities</p>


                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-08" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/11.Management-Software-Service.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  





    

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-09>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/brainstorm-online-marketing-strategy.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Custom Theme Design</h4>
                                <p class="text-muted mb-0">For WordPress / Blogger Free Blog</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-09" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/17.Blogger-Custom-Theme-Design.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  








            </div>

                    </Slide>
                    <Slide>


                <div class="row"  style="margin-right: 0px; margin-left: 0px;">














    

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-11>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/keyword-content-marketing.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Effective SEO Energy</h4>
                                <p class="text-muted mb-0">Essential On-page SEO Toolset</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-11" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/06.Effective-SEO-Energy.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  










    

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-12>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/link-building.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Link Building Service</h4>
                                <p class="text-muted mb-0">Increase Domain DA and PA Scores</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-12" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/06.Link-Building-Service.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  










    

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-13>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/Reputation-Management.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Reputation Management</h4>
                                <p class="text-muted mb-0">Improve Business is view Online</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-13" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/14.Reputation-Management.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  





    

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-14>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/Facebook-Ads-Management.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Facebook Ads Management</h4>
                                <p class="text-muted mb-0">Ads Manager for running ads on FB</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-14" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/10.PPC--Online-Advertising-Services-in-Bangladesh.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  







    

           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-15>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/PPC-Marketing-Service.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">PPC Marketing Service</h4>
                                <p class="text-muted mb-0">Highly visible Google Ads & Others</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-15" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/10.PPC--Online-Advertising-Services-in-Bangladesh.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  







           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-16>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/Setup-Email-Marketing.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Setup Email Marketing</h4>
                                <p class="text-muted mb-0">autoresponder market automation</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-16" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/07.-Email-Marketing-Services-in-Bangladesh.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  







           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-17>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/Local-SMS-Marketing.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Local SMS Marketing</h4>
                                <p class="text-muted mb-0">Text Messaging as a Marketing</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-17" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/13.Local-SMS-Marketing.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  





           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-18>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/Affiliate-Marketing-Strategy.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Affiliate Marketing Strategy</h4>
                                <p class="text-muted mb-0">Setup Affiliate Program For Store</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-18" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/16.Affiliate-Marketing-Strategy.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  





           
        
        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
        <div class="media contact-detail align-items-center">
        <div class="media-body content">
        <a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-19>
          <div class="media key-feature align-items-center p-3 rounded shadow">
                           <img src="images/icon/Research-Data-Entry.png" class="avatar avatar-40x" alt="">
                            <div class="media-body ml-3">
                                <h4 class="title mb-0 text-dark">Research & Data Entry</h4>
                                <p class="text-muted mb-0">Web Scraping and Data Mining</p>
                            </div>
                        </div>
        </a>
        </div><!--end col-->                      
        </div>

        <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-19" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img height="420" width="420" src="images/service/18..Research-Data-Entry.jpg" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
        </div>
        </div>
        </b-modal>
        </div>
        <!--End Service Area-->  





            </div>

                    </Slide>

                  </carousel>
                </div>
                <!--end col-->


          <div class="col-12 mt-4 pt-2 text-center">

<span class="responsive-small text-muted font-weight-bold">and a whole lot more … every step to online success.</span> 
          </div>
          <!--end col-->



        </div>
        <!--end row-->
      </div>
      <!--end container-->









          <div class="col-12 mt-4 pt-2 text-center">
                <div class="alert alert-primary alert-pills shadow" role="alert">          
                <span class="badge badge-pill badge-danger mr-1">or</span>
                <span class="content">
                  Do you need personal <span class="my-color-2">Creative Assistant?</span></span
                >
              </div>
    

  <br>   <br> 

              <h4 class="title mb-4">Work with our team remotely from anywhere and anytime</h4>
            

          </div>
          <!--end col-->



      
     <div class="container">
        <div class="row align-items-center">

          <div class="col-lg-5 col-md-6">
            <img 
              src="images/mysvg/anywhere2.svg"
              class="img-fluid"
              alt=""
            />
          </div>
          <!--end col-->



          <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="section-title text-md-left text-center">

              <h5 class="my-color-1  font-weight-bold">
                Types of contracts :  
                <vue-typer
                  :text="['Package Plan', 'Task Hour', 'Daily plan', 'Monthly Plan',  'Project Milestones', ]"
                ></vue-typer>
              </h5>
              

              <p class="text-muted mb-0 para-desc">
                It’s about understanding the internet <span v-b-tooltip.hover title="Karukaj best search engine marketer's expert digital 
                marketing strategy energizes every website every month and because of the custom website design expertise, Karukaj is one of the best website development company in Dhaka, BD." 
                class="text-muted" >marketing challenges</span> behind your business
              </p>

  
             <p class="mt-3 h6 my-color-1 font-weight-bold           
"
                >How do we works ?</p>

                                              <p class="text-muted mb-0 para-desc">
                We work with passion to bring your ideas to life. We push ourselves. We push technology, And we push the boundaries of conventional thinking
              ..</p>
      
           <p class="mt-3 font-weight-bold ">  We believe: <span class="my-color-1">Partner + Solution = Results</span> </p>
     

  <h6 class="mt-3 h6 my-color-1 font-weight-bold"> Our Process of Work:</h6>

  <p class="responsive-small mt-3">Strategy <i class="mdi mdi-chevron-right"></i> Consulting <i class="mdi mdi-chevron-right"></i> 
    Design <i class="mdi mdi-chevron-right"></i> Development <i class="mdi mdi-chevron-right"></i> Marketing</p>



       
                        
              <p class="mt-3 h6 my-color-1 font-weight-bold">Don't know where to start?</p>


              
              <ul class="responsive-small list-unstyled">
                <li>
                  <span class="text-yellow h5 mr-2"><i class="uil uil-star align-middle"></i> </span>We can start a brainstorming free project storytelling
                </li>
                <li>
                  <span class="text-yellow h5 mr-2"><i class="uil uil-star align-middle"></i> </span> Or, we'll take full responsibility for non-tech members.
                </li>
              </ul>




            </div>
          </div>
          <!--end col-->




        </div>
        <!--end row-->
      </div>
      <!--end container-->


    </section>
    <!--end section-->
    <!-- End -->

 














    <div class="container">
  

<div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                  <h4 class="title mb-4"> let’s make things happen</h4>

             
          <p class="text-muted para-desc mb-0 mx-auto">
         We’ve been in business for
         <span v-b-tooltip.hover title="Karukaj Digital is especially popular among the ancient best website design company in dhaka bangladesh since 2006. Currently, 
         launches as a full fledged Digital Marketing Agency in Bangladesh." 
          class="text-muted" >over 13 years</span>, 
          where our creativity is your success that can provide everything you need to generate awareness, drive traffic, connect.
        </p>



          </div>
          </div>
          <!--end col-->




  <div class="row">
    <div class="col-md-4 mt-4 pt-2">

      <div class="card features work-process bg-transparent process-arrow border-0 text-center" >

      

        <div class="icons rounded h1 text-center my-color-1 px-3">
          <i class="uil uil-flip-v"></i>
        </div>

        <div class="card-body">
          <h4 class="title text-dark">1. Find the Solution you need</h4>
          <p class="responsive-small text-muted mb-0">
            <span v-b-tooltip.hover title="In addition to complete web design and development service in Bangladesh, 
            we also provide one-page web page design at low cost. " class="text-muted"> Compare prices,</span>  portfolios, community recommendations and more to select the Talent 
            you are most excited to work with. If you have a specific question, simply send them an enquiry.
          </p>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
      <div
        class="card features work-process bg-transparent process-arrow border-0 text-center"
      >
        <div class="icons rounded h1 text-center my-color-1 px-3">
          <i class="uil uil-layer-group"></i>
        </div>
      

        <div class="card-body">
          <h4 class="title text-dark">2. Supply your brief</h4>
          <p class="responsive-small text-muted mb-0">
            Provide a job brief for the Talent to review. At this point KD member's portal takes payment, where you have to pay half of the full amount in advance and you are happy with the outcome.
          </p>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
      <div
        class="card features work-process bg-transparent d-none-arrow border-0 text-center"
      >
        <div class="icons rounded h1 text-center my-color-1 px-3">
          <i class="uil uil-object-group"></i>

        </div>

        <div class="card-body">
          <h4 class="title text-dark">3. Manage your job online</h4>
          <p class="responsive-small text-muted mb-0">
           Share files or feedback easily with inbuilt <span v-b-tooltip.hover title="KD Network is a digital agency management area, 
from which we manage the full digital marketing service list, create billing info and collect payment." class="text-muted">KD member's</span> messaging & job management tools . 
           Our team will complete the job within the agreed turnaround time, and revisions.


          </p>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->


</div>
<!--end container-->























    <div class="container mb-4">





      <div class="row mb-4 justify-content-center">
          <div class="col-12">
            <div class="section-title text-center pb-2">
              <h4 class="title"><span class="my-color-1 font-weight-bold">have a project in mind?</span> <br/>
              <small>we’d love to make your ideas into reality!</small></h4>              
            </div>
          </div>
          <!--end col-->
        </div>








      
        <div class="row align-items-center">






          <div
            class="col-lg-7 col-md-6 order-2 order-md-1 pt-2 mt-sm-0 pt-sm-0"
          >

          <div class="text-center">
              <img   width="80%" height="380" 
                src="images/mysvg/social-care.svg"
                class="img-fluid"
                alt=""
              />
            </div>



            <p class="text-muted para-desc">
                <span v-b-tooltip.hover title="Karukaj is one of the best digital marketing 
agency in Bangladesh or best web development company in Bangladesh near Dhaka. On the other hand our website design price in Bangladesh
 is very cheap. Also popular as the best eCommerce website development company in Bangladesh. Be around us." 
                class="text-muted" >Karukaj DIGITAL</span> offer a results-driven holistic marketing approach to increase your brand’s reach and dominate your competition.
              </p>
 

              <h4 class="mt-3 h6 my-color-1 font-weight-bold">What makes us unique?</h4>
              

              <ul class="responsive-small list-unstyled text-muted">
                 
              <li class="mb-0"> <span class="my-color-1 h5 mr-2">
                
                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

                
              </span>Ensure privacy and transparency in login & ownership</li>


               <li class="mb-0"> <span class="my-color-1 h5 mr-2"><arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Offer world class technologies at your fingertips</li>

              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Ensure simplicity and minimalistic UI/UX Design</li>

              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Serve within time without compromising on quality</li>

 
              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Create high impact dynamic visuals that sell anything</li>


              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Always pay attention to brand's SEO and Storytelling</li>


              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Focus on more traffic for mind-blowing possibilities</li>


              </ul>

                            <div class="col-12 text-center">


                                <a href="javascript:void(0)" class="my-color-1 h6"><span v-b-tooltip.hover title="Our creative interactive after effect promo animation, or Ads, 
                                photography, 
video editing, and video post production service will charge your business marketing several times more forever." 
                >Explore more than just “enjoy your stay!” </span> <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                            </div>


          </div>
          <!--end col-->




          <div class="col-lg-5 mt-4 col-md-6 order-1 order-md-2">

      
              


              <div class="card rounded bg-white shadow-lg border-0">
      <div class="card-body">

        <div class="text-center">

       <div class="mt-4 pt-2">
 
          <h4 class="title font-weight-bold">Create A <span class="my-color-1 font-weight-bold">Free Account</span></h4>  
          <p class="text-secondary">If you wish to join inside our task management <br> support area to analyze real-time project progress.</p>

     </div>
   </div>
   <!--end col--> 
           
           
           <iframe width="100%" height="520" src="https://members.karukaj.net/forms/wtl/5344a22f754a486154add6b818665191" frameborder="0" allowfullscreen></iframe>


          </div>
              <!--end col-->
         

         </div>












  
          </div>
          <!--end col-->



        </div>
        <!--end row-->
      </div>
      <!--end container-->

























    <section
      class="section bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build.png') +
          ') bottom no-repeat',
      }"
    >
  
      

















      <div class="container mt-100">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="pt-2">
                <h4 class="text-secondary">So, are you the kind of entrepreneur who takes action?</h4>
 <a   v-b-tooltip.hover title="Stay with Good Works"  href="/explore" class="btn btn-soft-light font-weight-bold mt-4"
                  >EXPLORE MORE<i class="mdi mdi-chevron-right"></i></a
                >
                <div class="mt-100 text-center">  
                  
                
                
                
                
                
                </div>





















                
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->














    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer ">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#4e4e4e"></path>
            </svg>
        </div>
    </div>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-light btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>





</template>
